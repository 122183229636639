var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-col w-full" }, [
    _c(
      "div",
      { attrs: { id: "extra-component-form-wizard-demo" } },
      [
        _c("h5", { staticClass: "text-center" }, [
          _c("strong", [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t(_vm.resources.BusinessCenters.i18n) ||
                    _vm.resources.BusinessCenters.name
                ) +
                "\n      "
            )
          ])
        ]),
        _c(
          "form-wizard",
          {
            attrs: {
              color: "rgba(var(--vs-primary), 1)",
              title: null,
              subtitle: null,
              "next-button-text":
                _vm.$t(_vm.resources.Next.i18n) || _vm.resources.Next.name,
              "back-button-text":
                _vm.$t(_vm.resources.Back.i18n) || _vm.resources.Back.name,
              "finish-button-text":
                _vm.$t(_vm.resources.Finish.i18n) || _vm.resources.Finish.name
            }
          },
          [
            _c("tab-content", [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("vx-card", [
                    _c(
                      "div",
                      { attrs: { slot: "no-body" }, slot: "no-body" },
                      [
                        _c(
                          "vs-table",
                          {
                            attrs: { data: _vm.businessCenter1 },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var data = ref.data
                                  return _vm._l(data, function(tr, indextr) {
                                    return _c(
                                      "vs-tr",
                                      { key: indextr },
                                      [
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].description
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  data[indextr].description
                                                )
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            staticClass: "text-right",
                                            attrs: {
                                              data: data[indextr].lastWeek
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(data[indextr].lastWeek)
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            staticClass: "text-right",
                                            attrs: {
                                              data: data[indextr].currently
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(data[indextr].currently)
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            staticClass: "text-right",
                                            attrs: {
                                              data: data[indextr].acumulated
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(data[indextr].acumulated)
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  })
                                }
                              }
                            ])
                          },
                          [
                            _c(
                              "template",
                              { slot: "thead" },
                              [
                                _c("vs-th", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t(
                                          _vm.resources.Description.i18n
                                        ) || _vm.resources.Description.name
                                      ) +
                                      "\n                  "
                                  )
                                ]),
                                _c("vs-th", { staticClass: "text-right" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t(
                                          _vm.resources.CarryInLastPeriod.i18n
                                        ) ||
                                          _vm.resources.CarryInLastPeriod.name
                                      ) +
                                      "\n                  "
                                  )
                                ]),
                                _c("vs-th", { staticClass: "text-right" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t(
                                          _vm.resources.CurrentPeriod.i18n
                                        ) || _vm.resources.CurrentPeriod.name
                                      ) +
                                      "\n                  "
                                  )
                                ]),
                                _c("vs-th", { staticClass: "text-right" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t(
                                          _vm.resources.CarryNextPeriod.i18n
                                        ) || _vm.resources.CarryNextPeriod.name
                                      ) +
                                      "\n                  "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ]),
            this.nroCenters > 1
              ? _c("tab-content", [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("vx-card", [
                        _c(
                          "div",
                          { attrs: { slot: "no-body" }, slot: "no-body" },
                          [
                            _c(
                              "vs-table",
                              {
                                attrs: { data: _vm.businessCenter2 },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var data = ref.data
                                        return _vm._l(data, function(
                                          tr,
                                          indextr
                                        ) {
                                          return _c(
                                            "vs-tr",
                                            { key: indextr },
                                            [
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data[indextr].description
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data[indextr]
                                                          .description
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  staticClass: "text-right",
                                                  attrs: {
                                                    data: data[indextr].lastWeek
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data[indextr].lastWeek
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  staticClass: "text-right",
                                                  attrs: {
                                                    data:
                                                      data[indextr].currently
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data[indextr].currently
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  staticClass: "text-right",
                                                  attrs: {
                                                    data:
                                                      data[indextr].acumulated
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data[indextr].acumulated
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        })
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  178199863
                                )
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "thead" },
                                  [
                                    _c("vs-th", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.Description.i18n
                                            ) || _vm.resources.Description.name
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                    _c("vs-th", { staticClass: "text-right" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.CarryInLastPeriod
                                                .i18n
                                            ) ||
                                              _vm.resources.CarryInLastPeriod
                                                .name
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                    _c("vs-th", { staticClass: "text-right" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.CurrentPeriod.i18n
                                            ) ||
                                              _vm.resources.CurrentPeriod.name
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                    _c("vs-th", { staticClass: "text-right" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.CarryNextPeriod.i18n
                                            ) ||
                                              _vm.resources.CarryNextPeriod.name
                                          ) +
                                          "\n                  "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ])
              : _vm._e(),
            this.nroCenters > 2
              ? _c("tab-content", [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("vx-card", [
                        _c(
                          "div",
                          { attrs: { slot: "no-body" }, slot: "no-body" },
                          [
                            _c(
                              "vs-table",
                              {
                                attrs: { data: _vm.businessCenter3 },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var data = ref.data
                                        return _vm._l(data, function(
                                          tr,
                                          indextr
                                        ) {
                                          return _c(
                                            "vs-tr",
                                            { key: indextr },
                                            [
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data[indextr].description
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data[indextr]
                                                          .description
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  staticClass: "text-right",
                                                  attrs: {
                                                    data: data[indextr].lastWeek
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data[indextr].lastWeek
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  staticClass: "text-right",
                                                  attrs: {
                                                    data:
                                                      data[indextr].currently
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data[indextr].currently
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  staticClass: "text-right",
                                                  attrs: {
                                                    data:
                                                      data[indextr].acumulated
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data[indextr].acumulated
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        })
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  178199863
                                )
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "thead" },
                                  [
                                    _c("vs-th", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.Description.i18n
                                            ) || _vm.resources.Description.name
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                    _c("vs-th", { staticClass: "text-right" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.CarryInLastPeriod
                                                .i18n
                                            ) ||
                                              _vm.resources.CarryInLastPeriod
                                                .name
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                    _c("vs-th", { staticClass: "text-right" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.CurrentPeriod.i18n
                                            ) ||
                                              _vm.resources.CurrentPeriod.name
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                    _c("vs-th", { staticClass: "text-right" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.CarryNextPeriod.i18n
                                            ) ||
                                              _vm.resources.CarryNextPeriod.name
                                          ) +
                                          "\n                  "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ])
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }